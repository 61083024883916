.pr2Omslag {
  background-image: url('../../../public/assets/images/lin-omslag-bkg-pariktigt.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--orange);
  color: var(--white);
}

.cover-background {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 800px;
  margin-top: 0px;
  padding-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: var(--standardBlackBackground);
  text-decoration: none;
}

.cover-heading {
  min-width: auto;
  margin-top: 0px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding-top: 10px;
  padding-bottom: 0px;
  color: var(--white);
  font-size: 72px;
  line-height: 78px;
  font-weight: bold;
}

.cover-paragraph {
  max-width: 450px;
  min-width: auto;
  margin-right: 10px;
  padding-top: 10px;
  padding-right: 10px;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  color: var(--white);
  font-size: 20px;
  line-height: 28px;
}

/* Login Form*/
.prLoginContainer {
  padding-top: 140px;
  color: var(--white_8);
}

.prLoginContainer h2 {
  margin-bottom: 20px;
}

.prLoginContainer .lp--resetPasswordLink {
  line-height: 35px;
  vertical-align: middle;
}

.invitations.prLoginContainer {
  padding-top: 0px;
  margin-top: 60px;
}

.invitations.prLoginContainer:first-child {
  margin-top: 0px;
}

.prLogin-pageHeader {
  text-align: left;
  color: var(--behaviorPink);
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.prLogin-input {
  margin-bottom: 10px;
}

.prLogin-button {
  margin-top: 15px;
  background-color: var(--notification);
  color: var(--white);
  font-weight: bold;
  padding: 5px 20px;
}

.prLogin-button:hover,
.prLogin-button:focus {
  color: var(--white);
}
