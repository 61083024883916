@import '~jquery-ui/themes/base/core.css';
@import '~jquery-ui/themes/base/draggable.css';
@import '~jquery-ui/themes/base/autocomplete.css';
@import '~jquery-ui/themes/base/menu.css';
@import '~jquery-ui/themes/base/datepicker.css';
@import '~jquery-ui/themes/base/theme.css';

/*  Autocomplete - Uses class-names based on the library */

.autocomplete-suggestions {
  background: var(--white);
  overflow: auto;
  padding: 0px 0px 10px 0px;
  &:first-letter {
    text-transform: uppercase;
  }
}

.autocomplete-selected {
  .lp--userSelectionItem {
    background-color: var(--black_1);
  }
}

.lp--userSelectionItem {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  cursor: pointer;

  .lp--userAvatar {
    width: 25px;
    height: 25px;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    margin: 0px 11px 0px 0px;
    flex-shrink: 0;
  }

  .lp--userName {
    color: var(--standardBlackBackground);
    font-size: 14px;
    margin: 0px;
  }
}

.lp--authenticationModalAutocomplete {
  background: var(--buttonGreenBack);
  padding: 0px;
  width: 505px !important;
  border-radius: 4px;
  margin-left: 20px;

  .autocomplete-suggestion {
    padding: 10px 15px;
    color: var(--white);
    color: var(--black_6);
    font-size: 14px;
    border-bottom: 1px solid var(--black_1);
  }

  .autocomplete-selected {
    background-color: var(--black_5);
    color: var(--white);
  }
}

/*  End of autocomplete */

.pr2--formGroup {
  padding: 0px 45px;
  margin-bottom: 50px;

  .new-session & {
    margin-bottom: 30px;
  }

  .pr2--formGroup-headerCategory {
    font-size: 15px;
    color: var(--grey);
  }

  .pr2--formGroup-header {
    font-size: 24px;
    color: var(--white);
  }

  .pr2--forms-circleShareLabel {
    font-size: 15px;
    font-weight: lighter;
    color: var(--grey);
  }

  .pr2--forms-circleShareLink {
    font-size: 15px;
    font-weight: lighter;
    color: var(--white);
  }
}

.pr2--formFooter {
  border-top: 1px solid var(--white_2);
  text-align: right;
  padding: 12px 15px;
}

.pr2--formInput {
  border: 0px var(--transparent);
  background-color: var(--transparent);
  border-bottom: 1px solid var(--grey);
  color: var(--grey);
  line-height: 30px;
  vertical-align: middle;
  margin-bottom: 25px;
  width: 100%;
  font-size: 18px;
  resize: none;

  &.preferred_locale {
    width: auto;
  }

  &.pr2--formInput-light {
    color: var(--black_5);
    border-bottom: 1px solid var(--black_5);
  }

  &::placeholder {
    color: var(--white_6);
    font-style: italic;
  }
}
.pr2--formInput:focus {
  border-bottom: 1px solid var(--white_7);
  color: var(--white_8);
  outline: none;
  &.pr2--formInput-light {
    color: var(--black_9);
    border-bottom: 1px solid var(--headerGrey);
  }
}

.lp--formButton {
  padding: 0 17px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 18px;
  outline: none;
  border: none;
  margin-left: auto;
  border: 2px solid var(--behaviorGreen);
  font-size: 14px;
  background-color: var(--transparent);
  color: var(--black);
  float: right;
  line-height: 33px;
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
  font-weight: bold;

  &:not([disabled]):hover,
  &:not([disabled]):focus,
  &.lp--formButton-alwaysSolid {
    transition:
      background-color 0.2s ease-in-out,
      color 0.2s ease-in-out;
    background-color: var(--buttonGreenFront);
    color: var(--black);
  }
}

.pr2--forms-dateRangeSelector,
.pr2--forms-certification {
  .pr2--formInput {
    font-size: 15px;
    width: 45%;
    margin-bottom: 15px;
    min-height: 30px;

    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
      opacity: 0.5;
      cursor: pointer;
      transition: opacity 0.2s ease;

      &:hover,
      &:focus {
        opacity: 1;
        transition: opacity 0.2s ease;
      }
    }
  }

  .lp--dateRangeSelectorInputs {
    color: var(--grey);
    padding-left: 24px;

    &.disabled {
      opacity: 0.2;
    }
  }

  .p2--forms-dateRangeSelector-setDate {
    background-color: var(--menuButtonBorder);
    border-radius: 50%;
  }
  .pr2--forms-dateRangeSelector-setDateLabel {
    font-size: 15px;
    font-weight: lighter;
    color: var(--grey);
  }

  :checked + .pr2--forms-dateRangeSelector-setDateLabel {
    color: var(--white_8);
  }

  .pr2--createSessionPopinSettings & {
    margin-bottom: 0;
  }
}

.pr2--forms-UserSelector {
  position: relative;
  margin-bottom: 60px;

  .pr2--formInput {
    font-size: 16px;
    line-height: 26px;
    position: relative;
    display: block;
    border-bottom: 1px solid var(--white);
    color: var(--white);
    opacity: 0.7;
  }
  .pr2--formInput:hover,
  .pr2--formInput:focus {
    border-bottom: 1px solid var(--white_7);
    color: var(--white_7);
    opacity: 0.7;
  }
  .pr2--formInput:hover,
  .pr2--formInput:focus {
    opacity: 0.9;
    border-bottom: 1px solid var(--headerGrey);
    color: var(--white);
  }
  .pr2--formInput:focus {
    opacity: 0.9;
  }

  .lp--userSelectionList {
    margin: 15px 0px;
  }

  .lp--userSelectionListItem {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grey);
  }

  .lp--deleteUserSelectionItem {
    margin-left: auto;
    background-image: url(../../../public/assets/images/theme-2/close-white.svg);
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
    opacity: 0.5;
    background-size: 10px 10px;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  .lp--userSelectionItem {
    padding: 5px 0px;
  }

  .lp--userName {
    color: var(--grey);
    font-size: 16px;
  }

  .lp--participantHelpText {
    color: var(--white);
    opacity: 0;
    max-height: 0px;
    display: none;
    transition:
      opacity 0.2s,
      max-height 0.2s;
  }

  .pr2--formInput:focus ~ .lp--participantHelpText {
    opacity: 1;
    max-height: 2000px;
  }

  .lp--userSelectionList:empty + .lp--participantHelpText {
    display: block;
  }
}

.pr2--forms-UserSelector .lp--participantHelpText {
  opacity: 1;
  max-height: 200px;
}

.lp--styled-inputLabel {
  cursor: pointer;
  position: relative;
  padding-bottom: 4px;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    display: none;

    & ~ span {
      background-image: url(../../../public/assets/images/icons/checkbox-unselected.svg);
    }

    &:checked ~ span {
      background-image: url(../../../public/assets/images/icons/checkbox-selected.svg);
    }
  }

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    display: none;

    &:checked + span {
      background-image: url(../../../public/assets/images/icons/radio-selected.svg);
    }
  }

  span {
    background-image: url(../../../public/assets/images/icons/radio-unselected.svg);
    background-position: 0 2px;
    background-repeat: no-repeat;
    background-size: 14px 14px;
    content: '';
    padding-left: 24px;
    display: inline-block;
  }
}

.pr2--formDeleteBtn {
  outline: none;
  border: none;
  background-color: var(--transparent);
  color: var(--white_4);
  padding: 0px 0px 0px 20px;
  float: left;
}

.pr2--createSessionPopin {
  .pr2--createSessionPopinSettingsToggle {
    margin: 0 45px 12px;
    font-weight: normal;
    cursor: pointer;
    border-bottom: 1px solid var(--transparent);
    transition: border-color 0.2s ease-in-out;

    &:after {
      content: url(../../../public/assets/images/icons/arrow-down.svg);
      margin-left: 10px;
    }

    &.visible:after {
      content: url(../../../public/assets/images/icons/arrow-up.svg);
    }

    &:hover,
    &:focus {
      border-color: var(--white);
      transition: border-color 0.2s ease-in-out;
    }
  }

  .pr2--createSessionPopinSettings {
    &.visible {
      transition: max-height 0.3s ease-in-out;
      height: auto;
      max-height: 600px;
    }

    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    max-height: 0;
    margin-top: 12px;
  }

  .pr2--forms-certification,
  .pr2--createSessionPopinSettings {
    label {
      font-size: 15px;
      font-weight: lighter;
      color: var(--grey);
      width: auto;
      transition: color 0.2s ease;

      &:hover,
      &:focus {
        color: var(--white);
        transition: color 0.2s ease;
      }
    }

    input[type='checkbox'] {
      width: 16px;
    }

    .pr2--createSessionPopin-allowLeaving {
      input[type='radio'] {
        vertical-align: top;
        margin-left: 24px;
      }
      label {
        width: auto;
      }
      .pr2--createSessionPopin-allowLeaving-alternatives {
        padding-top: 6px;
        padding-left: 24px;
        &.disabled {
          opacity: 0.2;
        }
      }
    }
  }

  .pr2--forms-dateRangeSelector {
    .lp--dateRangeSelectorInputs {
      padding-left: 24px;
    }
  }

  .pr2--formDeleteBtn {
    padding-left: 20px;
    background: url(../../../public/assets/images/theme-2/icons/white-trash.svg) center left no-repeat;
    background-size: 14px;
    color: var(--white);
    opacity: 0.5;
    transition: 0.2s;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}

.pr2--formSubmit {
  background-color: var(--loopsGreen);
  color: var(--black);
  font-size: 14px;
  height: 36px;
  border-radius: 18px;
  padding: 0px 20px;
  border: 2px solid var(--transparent);
  transition: border-color 0.2s ease-in-out;
  font-weight: bold;

  &.disabled {
    border: 2px solid var(--menuButtonBorder);
    color: var(--menuButtonBorder);
    background-color: var(--transparent);
  }

  &:hover:not(&.disabled),
  &:focus:not(&.disabled) {
    border-color: var(--white);
    transition: border-color 0.2s ease;
  }
}

.deadline {
  z-index: 6;
  border: none;
  outline: none;
  font-size: 17px;
  width: 1px;
  height: 1px;
  padding: 0;
  flex: 1;
  background: none;
  box-shadow: none;
  color: var(--white);
  margin: 0;
  text-align: center;
  position: absolute;
}

.student-behavior-menu [data-state],
.student-behavior-menu [data-locked],
.student-behavior-menu .comment,
.student-behavior-menu .edit,
.student-behavior-menu .delete {
  width: 50px;
  height: 36px;
  float: right;
  background-size: 36px 30px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: inline-block;
}

.right-icon-menu .comment,
.behavior-state-wrapper [data-state],
.behavior-state-wrapper [data-locked] {
  width: 50px;
  height: 36px;
  /*background-image: url('../../../public/assets/images/icons/report-state-active.svg');*/
  background-size: 36px 30px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.behavior-state-wrapper,
.right-icon-menu .comment,
.behavior-state-wrapper [data-state],
.behavior-state-wrapper [data-locked] {
  display: inline-block;
}

.student-behavior-menu [data-state='active'],
.behavior-state-wrapper [data-state='active'] {
  background-image: url('../../../public/assets/images/icons/report-state-active.svg');
}

.student-behavior-menu [data-state='feedback'],
.behavior-state-wrapper [data-state='feedback'] {
  background-image: url('../../../public/assets/images/icons/report-state-feedback.svg');
}

.student-behavior-menu [data-state='approved'],
.behavior-state-wrapper [data-state='approved'] {
  background-image: url('../../../public/assets/images/icons/report-state-approved.svg');
  background-size: 16px 16px;
}

.student-behavior-menu [data-state='failed'],
.behavior-state-wrapper [data-state='failed'] {
  background-image: url('../../../public/assets/images/icons/report-state-failed.svg');
  background-size: 16px 16px;
}

.student-behavior-menu [data-locked='true'],
.behavior-state-wrapper [data-locked='true'] {
  background-image: url('../../../public/assets/images/icons/report-locked.svg');
  background-size: 24px 28px;
}

.student-behavior-menu [data-locked='false'],
.behavior-state-wrapper [data-locked='false'] {
  background-image: url('../../../public/assets/images/icons/report-not-locked.svg');
  background-size: 24px 28px;
}

.student-behavior-menu .comment,
.right-icon-menu .comment {
  background-image: url('../../../public/assets/images/icons/comments.svg');
  background-size: 24px 28px;
  background-position: center right;
}

.student-behavior-menu .edit {
  background-image: url('../../../public/assets/images/icons/edit.svg');
  background-size: 24px 28px;
}

.edit-mode .student-behavior-menu .edit {
  background-image: url('../../../public/assets/images/icons/close-blue.svg');
  background-size: 24px 28px;
}

.student-behavior-menu .delete {
  background-image: url('../../../public/assets/images/icons/form-field-invalid.svg');
  background-size: 24px 28px;
}

.right-icon-menu {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.state-and-lock.behavior-state-wrapper {
  display: flex;
}

.blog_post_render {
  clear: both;
}

.blog_post_render .delete,
.blog_post_render .btn-save,
.new_post .btn-save {
  display: none;
}

.blog_post_render.edit-mode .delete,
.blog_post_render.edit-mode .btn-save,
.new_post.edit-mode .btn-save {
  display: inline-block;
}

/*    Auth Modal Form Styles     */

.lp--form {
  display: flex;
  flex-direction: column;

  .lp--formSubmitCircular {
    margin-top: 30px;
  }
}

.lp--formSubmitCircular {
  width: 47px;
  height: 47px;
  border-radius: 200px;
  background-color: var(--white);
  border: none;
  outline: none;
  cursor: pointer;
  transition: transform 0.2s;
  transform: scale(1);

  &:hover,
  &:focus {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
}

.lp--formGroup {
  display: flex;
  flex-direction: row;
  margin: 0px 0px 15px 0px;

  .lp--formField {
    flex: 1;
    margin-bottom: 0px;
  }

  &.lp--formGroupTwoColumns {
    .lp--formField {
      flex: 0.5;
    }

    .lp--formField:first-child {
      margin-right: 24px;
    }
  }
}

.lp--formRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;

  .lp--formSubmitCircular {
    margin-top: 0px;
  }

  &.justify-content {
    justify-content: space-between;
  }
}

.lp--formField {
  margin: 0px 0px 15px 0px;
  width: 100%;

  .lp--formCheckboxLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 0px 0px 15px;

    label {
      font-weight: normal;
      margin: 0px;
      cursor: pointer;
    }

    input[type='checkbox'] {
      margin: 0px 15px 0px 0px;
    }
  }

  &:last-of-type {
    margin-bottom: 0px;
  }

  input,
  textarea {
    background-color: var(--transparent);
    padding: 12px 25px;
    width: 100%;
    outline: none;
    border: none;
    font-size: 15px;
    background-color: var(--black_2);
    border-radius: 400px;

    &::-webkit-input-placeholder {
      color: var(--white);
    }
    &::-moz-placeholder {
      color: var(--white);
    }
    &:-ms-input-placeholder {
      color: var(--white);
    }
    &:-moz-placeholder {
      color: var(--white);
    }
  }

  input[type='checkbox'] {
    width: initial;
  }

  textarea {
    border-radius: 15px;
  }
}

.lp--formErrorMessage {
  display: none;
  color: var(--orange);

  &.filled {
    display: block;
  }
}

/*  Parsley Global */

.parsley-errors-list {
  padding: 0px 0px 0px 26px;
  margin: 8px 0px 0px 0px;
  &.filled {
    display: block;
  }
}

.parsley-errors-list li,
.parsley-style-error {
  background-image: url(../../../public/assets/images/theme-2/icons/field-error.png);
  background-size: 14px 14px;
  background-position: left center;
  padding: 0px 0px 0px 20px;
  background-repeat: no-repeat;
}
