.lp--appFooter {
  display: block;
  margin-top: 135px;
  color: var(--grey);
  font-size: 17px;
  font-weight: normal;
  padding-bottom: 100px;

  .lp--appFooterContainer {
    padding-top: 95px;
    background-image: url(../../../public/assets/images/theme-2/loops-icon.svg);
    background-size: 24px 35px;
    background-repeat: no-repeat;
    background-position: 15px 0px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .lp--appFooterColumn {
      flex: 1;
      min-width: 200px;

      .lp--appFooterLink {
        display: inline-block;
        margin-bottom: 18px;
        position: relative;
        transition: color 0.2s ease-in-out;

        &:hover,
        &:focus {
          transition: color 0.2s ease-in-out;
          color: var(--white_8);
        }

        &:after {
          position: absolute;
          width: 15px;
          height: 15px;
          content: ' ';
          right: -25px;
          top: 50%;
          margin-top: -7px;
          opacity: 0.5;
          background-repeat: no-repeat;
        }
      }

      .lp--appFooterLinkExplore:after {
        background-image: url(../../../public/assets/images/theme-2/icons/footer/explore-loops.svg);
      }

      .lp--appFooterLinkWhat:after {
        background-image: url(../../../public/assets/images/theme-2/icons/footer/what-is-a-loop.svg);
      }

      .lp--appFooterLinkWhatIs:after {
        background-image: url(../../../public/assets/images/theme-2/icons/footer/what-is-loops.svg);
      }
    }
  }

  .lp--appFooterSender {
    margin-top: 60px;
    background-image: url(../../../public/assets/images/loops-icon-green.svg);
    background-size: 48px 48px;
    background-repeat: no-repeat;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 65px;

    a {
      text-decoration: underline;
    }

    p {
      margin: auto 0px;
    }
  }
}
