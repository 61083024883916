@import '~bootstrap';
@import './tmpusability.css.scss';
@import './colors.css.scss';
@import './libs/lightbox.css.scss';
@import './modals.css.scss';
@import './panels.css.scss';
@import './footer.css.scss';
@import './flash.css.scss';
@import './forms.css.scss';
@import './teacher_panel.css.scss';
@import './frontpage.css.scss';
@import './settings.css.scss';
@import './collections.css.scss';
@import './auth_modals.css.scss';
@import './code_input.css.scss';
@import '~yet-another-react-lightbox/styles.css';
@import '~yet-another-react-lightbox/plugins/captions.css';
@import '~yet-another-react-lightbox/plugins/counter.css';

@font-face {
  font-family: 'Glyphicons Halflings';

  src: url('../../../public/assets/fonts/glyphicons-halflings-regular.eot');
  src:
    url('../../../public/assets/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
    url('../../../public/assets/fonts/glyphicons-halflings-regular.woff2') format('woff2'),
    url('../../../public/assets/fonts/glyphicons-halflings-regular.woff') format('woff'),
    url('../../../public/assets/fonts/glyphicons-halflings-regular.ttf') format('truetype'),
    url('../../../public/assets/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

html {
  -webkit-font-smoothing: antialiased;
  scroll-behavior: auto !important;
}

body {
  /*   font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
*/
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  padding: 0;
  margin: 0;
  background-color: var(--standardBlackBackground);
  line-height: 1.4;

  &.modal-open,
  &.news-open,
  &.trial-open,
  &.show-mask {
    overflow: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;

    &.scrollbar {
      padding-right: 16px;
    }

    #fc_frame {
      display: none;
    }
  }

  ::selection {
    color: var(--darkHorizonBackground);
    background-color: var(--searchFieldPlaceholder);
  }
}

legend {
  all: unset;
  padding: 0 10px;
}

p {
  margin: 0 0 15px;
}

.iframe > iframe {
  border: 1px solid var(--greyBorder);
  height: 100vh;
}

.delete-account {
  color: var(--white);
  padding-top: 135px;

  &_wrapper {
    margin: 0 auto;
    max-width: 1170px;
    padding: 15px;
  }

  &_heading {
    font-size: 36px;
    line-height: 78px;
  }

  &_description {
    font-size: 20px;
    padding-top: 10px;

    a,
    a:hover,
    a:visited {
      text-decoration: underline;
      text-underline-offset: 6px;
    }
  }
}

.pul {
  color: var(--white);
  padding-top: 135px;

  &_wrapper {
    margin: 0 auto;
    max-width: 1170px;
    padding: 15px;
  }

  &_title {
    font-size: 36px;
    line-height: 78px;
  }

  &_subtitle {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  &_heading {
    font-size: 20px;
    line-height: 24px;
  }

  &_text {
    padding-top: 10px;

    a,
    a:hover,
    a:visited {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
}

.container {
  max-width: initial;
}

.pr2--darkCoverContainer,
.pr2--featuredProductJumbotron {
  background: var(--transparent);
}

.pr2--darkCoverContainer:after,
.pr2--featuredProductJumbotron:after {
  display: none;
}

body.horizon {
  .pr2--darkCoverContainer,
  .pr2--featuredProductJumbotron {
    background: var(--transparent);

    &:first-child {
      background: var(--darkHorizonBackground);
      overflow: hidden;
    }

    &:first-child:after {
      content: ' ';
      width: 16000px;
      height: 16000px;
      background: var(--standardBlackBackground) !important;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: calc(100% - 70px);
      left: calc(-8000px + 50%);
    }
  }

  .pr2--darkCoverContainer,
  .pr2--featuredProductJumbotron {
    &:first-child:after {
      display: block;
    }
  }
}

.lp--containerHorizon {
  width: 100%;
  height: 70px;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;

  &:after {
    content: ' ';
    width: 16000px;
    height: 16000px;
    background: var(--darkHorizonBackground) !important;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: calc(100% - 70px);
    left: calc(-8000px + 50%);
  }
}

.lp--searchContainer {
  overflow: hidden;

  .pr2--darkCoverContainer,
  .pr2--featuredProductJumbotron {
    background: var(--transparent);
  }
}

::selection {
  background-color: var(--organizationGreen);
}

mark {
  padding: 0px;
  background-color: var(--organizationGreen);
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.circle-pane,
.hub-pane {
  ::selection {
    background-color: var(--behaviorGreen);
  }

  mark {
    padding: 0px;
    background-color: var(--behaviorGreen);
    cursor: pointer;
  }
}

.page {
  min-height: 100%;
}

.ui-datepicker {
  z-index: 11 !important;
}

label {
  margin: 0px;
  font-weight: normal;
}

.clear {
  clear: both;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
}

a,
div,
li,
span,
button,
label {
  &:focus {
    outline: none;
  }
}

a,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}

a.image-reference {
  text-align: center;
  display: block;
  font-size: 12px;
}

input[type='radio'][disabled],
input[type='checkbox'][disabled],
input[type='radio'].disabled,
input[type='checkbox'].disabled,
fieldset[disabled] input[type='radio'],
fieldset[disabled] input[type='checkbox'] {
  cursor: default;
}

.dragging {
  opacity: 0.6;
}

hr {
  border-top: 1px solid var(--white_7);

  .node-pane & {
    border-color: var(--black_3);
  }
}

img.svg {
  fill: var(--white);
}

.ui-autocomplete {
  z-index: 1000;
}

.italic {
  font-style: italic;
}

b,
strong {
  font-weight: bold;
}

/* MOVE TO SEPARATE FILE */

/* Switch Toggle */
.pr2--switchToggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.pr2--switchToggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

input.pr2--switchToggleRound + label {
  padding: 2px;
  width: 40px;
  height: 24px;
  margin: 0px 8px 0px 0px;
  background-color: var(--greyBorder);
  border-radius: 60px;
}

input.pr2--switchToggleRound + label:before,
input.pr2--switchToggleRound + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: '';
}

input.pr2--switchToggleRound + label:before {
  right: 1px;
  background-color: var(--black_4);
  border-radius: 24px;
}

input.pr2--switchToggleRound + label:after {
  width: 22px;
  background-color: var(--white);
  border-radius: 100%;
  box-shadow: 0 2px 5px var(--black_4);
  transition: margin 0.4s;
}

input.pr2--switchToggleRound:checked + label:before {
  background-color: var(--minimumGreenIcon);
}

input.pr2--switchToggleRound:checked + label:after {
  margin-left: 16px;
}

.lp--teacherPanelLink {
  margin-left: 20px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  align-self: center;
  display: block;
  background: url(../../../public/assets/images/theme-2/icons/link_arrow_green.svg) center center no-repeat;
  transform: scale(1);
  transition: transform 0.2s ease;

  &:hover,
  &:focus {
    transform: scale(1.2);
    transition: transform 0.2s ease;
  }
}

.lp--infoText {
  padding-left: 20px;
  background: url(../../../public/assets/images/theme-2/icons/information-icon.svg) left center no-repeat;
  background-size: 5px auto;
  margin-top: 40px;
}

.lp--userAvatarContainer {
  pointer-events: all;
  position: absolute;
  z-index: 9;
  top: 20px;
  right: 0px;
}

.nav {
  .lp--userAvatarContainer {
    position: relative;
    top: 24px;

    .lp--userAvatar {
      margin-right: 0px;
    }
  }
}

.navbar-nav > li {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;

  &.lp--navActiveLink {
    > a {
      position: relative;

      &:after {
        content: ' ';
        display: block;
        position: absolute;
        bottom: -2px;
        left: -4px;
        right: -4px;
        height: 2px;
        background: var(--buttonGreenBack);
        overflow: visible;
      }
    }
  }
}

.lp--userAvatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin: 0px 29px 0px 0px;
  background-size: cover;
  background-position: center center;
  position: relative;
  display: block;
}

/*	Tooltip */

.lp--toolTip {
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: var(--black);
  color: var(--black);
  padding: 3px 11px;
  border-radius: 10px;
  z-index: 99;
  font-size: 12px;

  &.display {
    opacity: 1;
  }

  &.lp--toolTipArticle,
  &.lp--toolTipLegend {
    background-color: var(--behaviorYellow);
  }

  &.lp--toolTipGroup,
  &.lp--toolTipPublicBlog {
    background-color: var(--behaviorGreen);
  }

  &.lp--toolTipPrivateBlog,
  &.lp--toolTipTask,
  &.lp--toolTipQA {
    background-color: var(--behaviorBlue);
  }

  &.lp--toolTipCorrelation,
  &.lp--toolTipQuiz {
    background-color: var(--behaviorPinkFront);
  }

  &.lp--toolTipEvaluation {
    background-color: var(--behaviorPurpleFront);
  }

  &.lp--toolTipEdge {
    background-color: var(--behaviorPurpleFront);
  }
}

/*	Image Uploader - Move to separate file! */

.pr2--imageUploader input[type='file'] {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: var(--transparent);
  opacity: 0;
  cursor: pointer;
}

::-webkit-file-upload-button,
input[type='file'] {
  cursor: pointer;
}

.pr2--imageUploadSection {
  display: flex;
}

.pr2--imageUploader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pr2--imageUploadPreview {
  height: 35px;
  width: 35px;
  border-radius: 50% 50%;
  background-size: cover;
  margin: 0px 0px 0px 20px;
  transform: scale(1);
  transition: transform 0.2s ease;
  cursor: pointer;

  &.loading {
    background-image: url(../../../public/assets/images/theme-2/loaders/loader_green-on-gray.gif) !important;
    background-size: initial;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &:hover,
  &:focus {
    transform: scale(1.2);
    transition: transform 0.2s ease;
  }

  &[data-url=''] {
    display: none;
  }
}

/* Bootstrap Overrides */

.navbar-header {
  display: flex;
  align-items: center;
  height: 60px;
}

.hidden {
  display: none !important;
}

.navbar-default {
  background-color: var(--darkHorizonBackground);
  border: none;
  position: fixed;
  top: 0;
  z-index: 20;
  left: 0;
  right: 0;

  .show-mask & {
    z-index: 0;
  }

  .navbar-brand {
    height: 42px;
    img {
      display: inline-block;
    }

    @media (min-width: 768px) {
      padding: 0px 22px 0px 27px;
    }

    background-repeat: no-repeat;
  }

  li {
    a {
      z-index: 2;
      position: relative;
      color: var(--white_8) !important;
      transition: 0.2s;
    }

    a:hover,
    a:focus {
      color: var(--white) !important;
    }

    .pr2--brandIcon {
      width: 16px;
      height: 20px;
    }

    .pr2--menuItemWithBrandIcon {
      background-position: center left;
      background-repeat: no-repeat;
      background-size: 17px;
    }
  }

  .navbar-collapse {
    background-color: var(--black);
    border-color: var(--transparent);

    a {
      padding: 5px 15px;

      &.pr2--menuItemWithBrandIcon {
        background-image: none;
      }
    }

    &.collapse {
      background-color: var(--transparent);

      a {
        padding: 6px 0;
        margin-top: 0;
        color: var(--white_8);
        font-weight: 400;
        margin-left: 23px;
        transition:
          background-color 0.2s ease-in-out,
          color 0.2s ease-in-out;

        &.lp--formButton:hover,
        &.lp--formButton:focus {
          transition:
            background-color 0.2s ease-in-out,
            color 0.2s ease-in-out;
          background-color: var(--behaviorGreen);
          color: var(--white) !important;
        }

        &.lp--formButton {
          padding: 6px 15px;
        }

        &.lp--navbarHomeButton {
          padding-left: 18px;
          background: url(../../../public/assets/images/theme-2/icons/home-white.svg) 0px 9px no-repeat;
          background-size: 13px 13px;
        }

        &.pr2--menuItemWithBrandIcon {
          background-image: url('../../../public/assets/images/theme-2/loops-icon.svg');
          padding-left: 24px;
        }
      }
    }
  }

  .pr2--searchIcon {
    display: inline-block;
    background-image: url('../../../public/assets/images/theme-2/search-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 25px;
    height: 20px;
    opacity: 0.5;
  }

  .prList-item-linkwrapper {
    color: inherit;
  }
}

.pr2-adaptiveSearchField {
  display: inline-block;
  background-color: var(--standardComponentBackground);
  border: 0px solid var(--transparent);
  background-image: url('../../../public/assets/images/theme-2/search-white-semi.svg');
  background-position: 10px center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  height: 30px;
  transition: 0.5s;
  color: var(--transparent);
  border-radius: 15px;
  width: 160px;
  margin: 6px 15px 12px 0;
  padding: 0px 10px 0px 34px;
  color: var(--white);
  opacity: 1;
  outline: none;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .navbar-brand {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }

  .pr2-adaptiveSearchField {
    margin-top: 18px;
  }
}

.pr2--lightCoverContainer {
  padding-top: 240px;
  background-image: url('../../../public/assets/images/theme-2/stars-bg-white.gif');

  li {
    a:hover,
    a:focus {
      color: var(--black) !important;
    }
  }

  .navbar-default {
    .navbar-brand {
      .pr2--brandWordmark {
        background-image: url('../../../public/assets/images/theme-2/loops-wordmark-dark.svg');
      }
    }

    .pr2--searchIcon {
      background-image: url('../../../public/assets/images/theme-2/search-black.svg');
    }
  }
}

.navbar-default .navbar-toggle {
  margin-left: 15px;
}

ol,
ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

img {
  overflow: hidden;
}

/* End of Bootstrap Overrides */

/* Icons */

.pr2--icon {
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
  border: 1px solid var(--black);
  border-radius: 50%;

  &.compass {
    background-image: url('../../../public/assets/images/theme-2/icons/compass.svg');
    /*background-position: 4px 5px;*/
    background-position: 40% 50%;
  }

  &.turnright {
    background-image: url('../../../public/assets/images/theme-2/icons/turnright.svg');
  }

  &.asterisk {
    background-image: url('../../../public/assets/images/theme-2/icons/asterisk.svg');
  }
}

/* End of Icons */

.lp--brandIcon {
  display: inline-block;
  background-image: url('../../../public/assets/images/theme-2/loops-product-logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 113px;
  height: 42px;
}

.pr2--brandWordmark {
  display: inline-block;
  background-image: url('../../../public/assets/images/theme-2/loops-wordmark-white.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 68px;
  height: 25px;
}

.pr2--featuredProductJumbotron {
  background-image: url('../../../public/assets/images/theme-2/stars-bg.gif');
  color: var(--white);
  background-color: var(--black);
  height: 800px;
  margin-bottom: 80px;
  overflow: hidden;

  .pr2--featuredProductJumbotron-container {
    height: 100%;
    display: flex;
    align-items: center;
    align-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 120px;
  }

  .pr2--contentListing-label {
    width: 80%;
    /* padding: 30px 0px 190px 88px; */
    padding: 30px 0px 0px 88px;

    .pr2--contentListing-subHeader {
      font-size: 15px;
      opacity: 0.5;
    }

    .pr2--contentListing-name {
      font-size: 43px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .pr2--contentListing-authors {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        display: inline-block;
        font-size: 14px;
        margin-right: 20px;
        line-height: 22px;
        color: var(--white_3);
      }

      img {
        width: 22px;
        height: 22px;
        background-color: var(--hoverGrey);
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }

  .pr2--contentListing-image {
    display: none;
    height: 100%;
    max-height: 556px;
    min-width: 200px;
  }
}

@media screen and (min-width: 1000px) {
  .pr2--featuredProductJumbotron {
    .pr2--contentListing-label {
      width: 50%;
    }

    .pr2--contentListing-image {
      display: inline-block;
    }
  }
}

.pr2--sectionHeader {
  h2 {
    font-weight: 400;
    font-size: 20px;
    color: var(--searchFieldPlaceholder);
  }

  margin-bottom: 80px;

  &.lp--searchSectionHeader {
    margin-bottom: 20px;

    h2 {
      font-size: 19px;
    }

    &.lp--noResultsInSection {
      opacity: 0.6;
    }
  }

  .lp--searchResultsCount {
    font-size: 12px;
  }
}

.pr2--contentItemListing {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin: 0px 0px 80px 0px;
}

.pr2--contentItem50,
.pr2--contentItem100 {
  display: flex;
  width: 100%;
  min-width: 400px;
  padding: 20px;
  box-sizing: border-box;
  margin: 0px 0px 20px 0px;

  > a {
    display: flex;
  }

  .pr2--contentListing-label {
    .pr2--contentListing-subHeader {
      font-size: 15px;
      opacity: 0.5;
    }

    .pr2--contentListing-name {
      font-size: 28px;
      margin-top: 8px;
      margin-bottom: 12px;
    }

    .pr2--contentListing-authors {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        display: inline-block;
        font-size: 14px;
        margin-right: 20px;
        line-height: 22px;
        color: var(--standardBlackBackground);
      }

      img {
        width: 22px;
        height: 22px;
        background-color: var(--hoverGrey);
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }

  .pr2--contentListing-imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 95px;
    min-height: 95px;
    width: 95px;
    min-width: 95px;
    border-radius: 50%;
    background-image: url('../../../public/assets/images/theme-2/stars-bg.gif');
    margin: 0px 20px 0px 0px;

    img {
      height: 65%;
      width: 65%;
      border-radius: 50%;
      background-color: var(--hoverGrey);
      overflow: hidden;
    }
  }
}

@media screen and (min-width: 1000px) {
  .pr2--contentItem50 {
    width: 50%;
  }
}

.row.pr2--contentListings {
  margin-bottom: 80px;
}

.pr2--lightContainer {
  padding-bottom: 80px;

  span {
    color: var(--white_7);
  }
}

.lp--full_dark_background {
  background-image: url('../../../public/assets/images/theme-2/stars-bg.gif');
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  overflow: auto;
}

.pr2--darkCoverContainer,
.pr2--featuredProductJumbotron {
  position: relative;
  padding: 80px 0;
  margin-bottom: 19px;
  width: 100%;
  background-image: url('../../../public/assets/images/theme-2/stars-bg.gif');
  color: var(--white);
  background-color: var(--black);

  &:after {
    content: ' ';
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0px;
    left: 0;
    background: url('../../../public/assets/images/horizon-white.svg') bottom center no-repeat;
  }
}

.pr2--featuredCollectionsList {
  margin: 0px 0px 90px 0px;
}

.pr2--featuredCollectionsList-item {
  width: 300px;
  height: 300px;
  min-width: 300px;
  min-height: 300px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-color: var(--hoverGrey);
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 8px 15px 8px;
  position: relative;
  transition: 0.2s;

  &:hover,
  &:focus {
    transform: scale(1.01);
  }

  > a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--black_5);
    border-radius: 50%;
  }

  .pr2--contentListing-subHeader {
    font-size: 14px;
    color: var(--white);
    opacity: 0.7;
  }

  .pr2--contentListing-name {
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
    font-size: 32px;
    color: var(--white);
    padding: 0px 15px;
    margin: 15px 0px 24px 0px;
  }

  .pr2--contentListing-collectionContent {
    font-size: 15px;
    color: var(--white);
    opacity: 0.7;
  }
}

.pr2--singleFeaturedItem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 120px;
  margin: 40px;

  .pr2--contentListing-label {
    .pr2--contentListing-subHeader {
      font-size: 15px;
      opacity: 0.5;
    }

    .pr2--contentListing-name {
      font-size: 43px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .pr2--contentListing-authors {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        display: inline-block;
        font-size: 14px;
        margin-right: 20px;
        line-height: 22px;
        color: var(--black_6);
      }

      img {
        width: 22px;
        height: 22px;
        background-color: var(--hoverGrey);
        border-radius: 50%;
        margin-right: 5px;
      }
    }
  }

  .pr2--contentListing-imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    min-height: 300px;
    width: 300px;
    min-width: 300px;
    border-radius: 50%;
    background-image: url('../../../public/assets/images/theme-2/stars-bg.gif');
    margin: 40px 20px 40px 0px;

    img {
      height: 65%;
      width: 65%;
      border-radius: 50%;
      background-color: var(--hoverGrey);
    }
  }
}

.pr2--footer {
  background-color: var(--standardBlackBackground);
  color: var(--white);
  padding: 40px 0px 60px 0px;
}

/* Public User Pages */

.pr2--userProfileSection {
  text-align: center;

  .pr2--userProfileSection-userImage {
    height: 120px;
    width: 120px;
    background-color: var(--hoverGrey);
    border-radius: 50%;
    display: inline-block;
    margin: 0px 0px 27px 0px;
    background-size: cover;
  }

  .pr2--userProfileSection-userName {
    font-size: 50px;
    font-weight: bold;
    margin: 0px 0px 42px 0px;
  }

  .pr2--userProfileSection-userByline {
    font-size: 17px;
    color: var(--headerGrey);
    margin: 0px 0px 35px 0px;
  }

  .pr2--userProfileSection-userBio {
    font-size: 17px;
    color: var(--headerGrey);
    max-width: 450px;
    margin: 0px auto 35px auto;
  }

  .pr2--userProfileSection-followBtn {
    background-color: var(--behaviorGreen);
    font-size: 14px;
    border-radius: 18px;
    color: var(--white);
    line-height: 35px;
    padding: 0px 25px 0px 25px;
    height: 35px;
    border: 0px solid var(--transparent);
  }

  .pr2--greenText {
    color: var(--loopsGreen);
  }

  .pr2--darkGreyText {
    color: var(--menuButtonBorder);
  }

  margin-bottom: 140px;
}

.pr2--userListing {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin: 0px 0px 40px;
  justify-content: center;
}

.pr2--userItem50 {
  display: flex;
  width: 100%;
  min-width: 400px;
  padding: 10px;
  box-sizing: border-box;
  margin: 0px 0px 20px 0px;

  .pr2--userItem50-userLabel {
    .pr2--userItem50-userLabel-userName {
      font-size: 18px;
      color: var(--standardBlackBackground);
      margin-bottom: 12px;
    }

    .pr2--userItem50-userLabel-userByLine {
      font-size: 14px;
      color: var(--standardBlackBackground);
      opacity: 0.8;
    }
  }

  .pr2--userItem50-userImage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    min-height: 60px;
    width: 60px;
    min-width: 60px;
    border-radius: 50%;
    background-color: var(--hoverGrey);
    margin: 0px 20px 0px 0px;
    background-size: cover;
  }
}

@media screen and (min-width: 1000px) {
  .pr2--userItem50 {
    width: 50%;
  }
}

/* End of Public User Pages */

/* User Home */

.pr2--subNav {
  margin-bottom: 75px;

  a {
    color: var(--searchFieldPlaceholder);
    font-size: 15px;
    padding: 0 0 7px;
  }

  li {
    padding: 0;
    margin-right: 40px;
    display: inline-block;
    margin-bottom: 20px;

    &:hover,
    &:focus,
    &.active {
      a {
        color: var(--white);
        border-bottom: 2px solid var(--white);
        text-decoration: none;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .pr2--subNav {
    li {
      width: auto;
      margin-bottom: 0px;
    }
  }

  .lp--profileSubnav-list {
    display: flex;
    min-height: 35px;
  }
}

.pr2--listColumn {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 74px;
}

.pr2--listColumn-listItem {
  padding: 14px 40px 14px 0;

  h5 {
    text-transform: none;
    color: var(--grey);
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
  }

  a {
    font-size: 15px;
    color: var(--white);
  }

  &.completed {
    a,
    a:hover,
    a:focus {
      color: var(--grey);
      text-decoration: line-through;
    }
  }

  &:first-child {
    padding-top: 0px;
  }

  &:last-child {
    padding-bottom: 33px;
  }
}

.pr2--listIconWrapper {
  /* Icons */

  width: 100%;
  height: 100%;
  padding-left: 60px;
  overflow: hidden;
  text-overflow: ellipsis;

  background-position: 23px center;
  background-repeat: no-repeat;
  background-size: 13px 13px;
  /* Temp */
  background-image: url('../../../public/assets/images/theme-2/icons/subcat.svg');

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/subcat.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/subcat.svg');
  }

  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/node-pane/subcat.svg');
    border-color: var(--minimumYellowIcon);
  }
}

.pr2--listIconWrapper-energy,
.pr2--listIconWrapper-article,
.pr2--listIconWrapper-chunk {
  background-image: url('../../../public/assets/images/theme-2/icons/subcat.svg');

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/subcat.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/subcat.svg');
  }

  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/node-pane/subcat.svg');
    border-color: var(--minimumYellowIcon);
  }
}

.pr2--listIconWrapper-video {
  background-image: url('../../../public/assets/images/theme-2/icons/video.svg');

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/video.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/video.svg');
  }

  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/node-pane/video.svg');
    border-color: var(--minimumYellowIcon);
  }
}

.pr2--listIconWrapper-link {
  background-image: url('../../../public/assets/images/theme-2/icons/link.svg');

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/link.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/link.svg');
  }

  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/node-pane/link.svg');
    border-color: var(--minimumYellowIcon);
  }
}

.pr2--listIconWrapper-group {
  background-image: url('../../../public/assets/images/theme-2/icons/connected.svg');

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/connected.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/connected.svg');
  }

  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/node-pane/connected.svg');
    border-color: var(--minimumGreenIcon);
  }
}

.pr2--listIconWrapper-private_blog {
  background-image: url('../../../public/assets/images/theme-2/icons/logbook.svg');

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/logbook.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/logbook.svg');
  }

  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/node-pane/logbook.svg');
    border-color: var(--minimumBlueIcon);
  }
}

.pr2--listIconWrapper-public_blog {
  background-image: url('../../../public/assets/images/theme-2/icons/noticeboard.svg');

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/noticeboard.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/noticeboard.svg');
  }

  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/node-pane/noticeboard.svg');
    border-color: var(--minimumGreenIcon);
  }
}

.pr2--listIconWrapper-quiz {
  background-image: url('../../../public/assets/images/theme-2/icons/quiz.svg');

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/quiz.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/quiz.svg');
  }

  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/quiz.svg');
    border-color: var(--behaviorPink);
  }
}

.pr2--listIconWrapper-written_quiz {
  background-image: url('../../../public/assets/images/theme-2/icons/written_quiz.svg');

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/written_quiz.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/written_quiz.svg');
  }
  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/node-pane/written_quiz.svg');
    border-color: var(--minimumBlueIcon);
  }
}

.pr2--listIconWrapper-legend {
  background-image: url('../../../public/assets/images/theme-2/icons/concept.svg');

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/concept.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/concept.svg');
  }

  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/node-pane/concept.svg');
    border-color: var(--minimumYellowIcon);
  }
}

.pr2--listIconWrapper-task {
  background-image: url('../../../public/assets/images/theme-2/icons/default.svg');

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/default.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/default.svg');
  }

  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/node-pane/default.svg');
    border-color: var(--minimumBlueIcon);
  }
}

.pr2--listIconWrapper-correlation {
  background-image: url('../../../public/assets/images/theme-2/icons/correlation.svg');

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/correlation.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/correlation.svg');
  }

  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/correlation.svg');
    border-color: var(--behaviorPink);
  }
}

.pr2--listIconWrapper-evaluation {
  background-image: url('../../../public/assets/images/theme-2/icons/evaluation.svg');

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/evaluation.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/evaluation.svg');
  }

  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/evaluation.svg');
    border-color: var(--behaviorPurple);
  }
}

.pr2--listIconWrapper-checklist {
  background-image: url('../../../public/assets/images/theme-2/icons/checklist.svg');
  background-size: 60%;

  &.gray {
    background-image: url('../../../public/assets/images/theme-2/icons/grayscale/checklist.svg');
  }

  &.done {
    background-image: url('../../../public/assets/images/theme-2/icons/done/checklist.svg');
  }

  .node-pane &,
  .lp--teacherPanel-contextView & {
    background-image: url('../../../public/assets/images/theme-2/icons/node-pane/checklist.svg');
    border-color: var(--minimumBlueIcon);
  }
}

.pr2--listIconWrapper-guide {
  background-image: url('../../../public/assets/images/theme-2/icons/question-circle-purple.svg');
  background-size: 20px 20px;
}

.lp--panelNavigation-activity .pr2--listIconWrapper-done {
  border: 2px solid var(--menuButtonBorder);
}

.lp--unitsIcon {
  width: 60px;
  height: 60px;
  background-image: url('../../../public/assets/images/shared/lists/info-icon.svg');
}

/* End of User Home */

/* Create Session Popin */

.pr2-popinHeader-close {
  border: 0px;
  width: 16px;
  height: 16px;
  background-image: url('../../../public/assets/images/theme-2/close-white.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--transparent);
  opacity: 0.5;
  position: absolute;
  right: 20px;
  transition: opacity 0.2s ease-in-out;
}

.pr2-popinHeader-close:hover,
.pr2-popinHeader-close:focus {
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
}

.pr2--createSessionPopin {
  border-radius: 6px;
  background-color: var(--standardBlackBackground);
  color: var(--white);
  width: 435px;
  outline: none;
  margin: 5% auto;
  padding: 22px 0px 0px 0px;
  border: 2px solid var(--menuButtonBorder);
  position: relative;

  .pr2--formInput-sessionName {
    font-style: italic;
  }
}

.popup-header {
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: 500;
  color: var(--white);
  margin: 30px 45px;
}

.lp--viewAlexandriaPopin {
  border-radius: 6px;
  background-color: var(--black_8);
  color: var(--white);
  width: 836px;
  margin: 5% auto;
  outline: none;
  padding: 22px 0px 0px 0px;

  h2 {
    color: var(--behaviorBlue);
    font-family: 'HelveticaNeue-Bold';
    font-size: 18px;
    font-weight: bold;
    margin: 8px 0 0 0;
    line-height: 30px;
    width: 100%;
    color: var(--grey);
  }

  h3 {
    color: var(--behaviorBlue);
    font-family: 'HelveticaNeue-Bold';
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 2px 0;
    line-height: 28px;
    width: 100%;
    color: var(--grey);

    &:empty {
      display: none;
    }
  }

  .section {
    margin: 0 0 32px 0;

    &:empty {
      display: none;
    }

    .annotation {
      margin: 0 0 16px 0;
      padding: 0 0 0 32px;
      background: url('../../../public/assets/images/icons/checkmark.svg') 0px 2px no-repeat;
      background-size: 18px;
      display: flex;

      .action {
        display: none;
      }

      > div {
        width: 685px;
        text-align: justify;
      }
    }
  }

  .annotation .action {
    background: 0px 0px no-repeat;
    background-size: 17px;
    width: 18px;
    height: 18px;
    margin: auto 0 auto auto;
  }

  .alexandria-view {
    margin-bottom: 0;

    .actions {
      margin: 0px 0 22px 0;
    }
  }

  .alexandria-edit {
    margin-bottom: 0;
    display: none;

    .actions {
      margin: 16px 0 22px 0;
    }
  }

  &[mode='add'] {
    .alexandria-view {
      display: none;
    }

    .alexandria-edit {
      display: block;
    }

    .annotation {
      background-image: url('../../../public/assets/images/icons/unchecked.svg');

      .action {
        display: inline-block;
        background-image: url('../../../public/assets/images/icons/lgr-not-yet-added.svg');
      }

      &.checked[data-value=''] .action {
        background-image: url('../../../public/assets/images/icons/lgr-to-be-added.svg');
      }

      &[data-value='checked'] {
        background-image: url('../../../public/assets/images/icons/checkmark.svg');

        .action {
          display: none;
        }
      }
    }
  }

  .lp--alexandria-add {
    display: none;
  }

  &[mode='edit'] {
    .annotation {
      .action {
        display: inline-block;
        background-image: url('../../../public/assets/images/icons/lgr-remove.svg');
      }
    }

    .lp--alexandria-add {
      display: inline-block;
    }
  }

  .result.loading {
    background: url('../../../public/assets/images/tmp/v2/loader.gif') no-repeat center center;
    min-height: 84px;
  }

  .lp--alexandria-edit {
    background: url('../../../public/assets/images/tmp/v2/icons/copyCircle.png') no-repeat center center;
    display: inline-block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    float: right;
  }
}

/* End of Create Session Popin */

/*		Task/Group/Panel-related 	*/
ul.task-reports,
ul.-task-reports,
ul.report-participants {
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex: 1;
}

ul.task-reports li {
  padding-bottom: 20px;
}

ul.task-reports li:last-child {
  margin-bottom: 0;
}

ul.task-reports li a {
  word-wrap: break-word;
  text-decoration: underline;
}

.taskReportRow .user-row {
  display: flex;
  flex: 1;
}

.taskReportRow .user-info {
  display: flex;
  flex: 3;
  align-items: center;
}

.pr2-blogPostList {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.pr2-blogPostList > li {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--black_1);
  padding-bottom: 20px;
}

.pr2-blogPostList .taskReportRow {
  align-items: initial;
  flex-direction: column;
  border-bottom: 1px solid var(--black_2);
  padding: 0px 0px 20px 0px;
  margin-bottom: 20px;
}

.pr2-blogPostList .taskReportRow > :last-child {
  margin-left: inherit;
}

.pr2-blogPostList .pr2-blogContentContainer {
  margin: 20px 0px 0px 0px;
}

.content-viewer .taskReportRow .user-info {
  padding: 0px;
}

.taskReportRow .user-info .taskReportUserName {
  margin-left: 0;
}

.taskReportRow.taskGroupRow {
  display: list-item;
}

.taskReportRow p {
  margin: 0px 10px 0px 0px;
}

.taskReportUserImage,
.taskReportRow .taskReportUserImage,
.taskGroupRow .taskReportUserImage {
  flex: none;
  margin: 0px 10px 0px 0px;
  width: 38px;
  height: 38px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  vertical-align: top;
}

.tastreports {
  counter-reset: li;
}

.taskGroupRow ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
}

.taskGroupRow {
  counter-increment: li;
}

.task-reports .right-icon-menu {
  margin-top: 0px;
}

.task-reports .state-and-lock {
  margin-top: 0px;
}

.taskGroupRow .label-item {
  margin-right: 24px;
}

.label-item.add-group {
  list-style-type: none !important;
  cursor: pointer;

  h1 {
    font-size: 15px;
    transition:
      color 0.2s ease,
      text-decoration 0.2s ease;
    border-bottom: none;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    h1 {
      color: var(--black);
      transition:
        color 0.2s ease,
        text-decoration 0.2s ease;
      text-decoration: underline;
    }
  }
}

.taskGroupRow h1 {
  display: inline-block;
  width: 56px;
  height: 56px;
  border-radius: 28px;
  background-color: var(--standardTextGrey);
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  flex: none;
  line-height: 56px;
  color: var(--buttonGreenFront);
  border: 1px solid var(--white);
}

.taskGroupRow h1::after {
  content: counter(li);
}

.taskGroupRow .details {
  display: inline-block;
  line-height: 24px;
  font-size: 14px;
  margin: 0 0 0 6px;
}

.taskGroupRow .author {
  width: 48px;
  height: 48px;
}

.taskGroupRow span.author {
  color: var(--headerGrey);
}

.taskReportRow .handin {
  color: var(--buttonGreenFront);
  font-size: 12px;
  visibility: hidden;
  display: block;
}

.taskReportRow .handin.filled {
  padding: 0;
  visibility: visible;
}

.taskReportRow .users-line {
  display: block;
  display: flex;
  flex: 3;
}

.taskGroupRow .users-line .right-icon-menu {
  margin-top: 20px;
}

.taskReportRow .handin.filled > div {
  background-image: url('../../../public/assets/images/icons/file-doc.svg');
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: 0 50%;
  min-height: 44px;
  padding: 0 0 0 48px;
}

.taskReportRow .handin.filled > div {
  background-image: url('../../../public/assets/images/corner-lines.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  margin: 0 0 0 25px;
}

.taskReportRow a.report-link:hover,
.taskReportRow a.report-link:focus {
  text-decoration: underline;
}

.taskreports {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.taskreports li.taskReportRow {
  background: none;
  padding: 12px 24px 12px 58px;
}

.task-reports li:nth-child(odd) {
  background-color: var(--transparent);
}

.taskreports li.taskReportRow:nth-child(even) {
  background-color: var(--white_1);
}

.task-reports li:nth-child(even),
.taskreports li.taskReportRow:nth-child(odd) {
  background-color: var(--white);
}

.taskreports .TaskParticipation {
  margin: 0px 12px 0px 0px;
  cursor: move;
}

.sessionTaskReport {
  margin: 0px 0px 80px 0px;
}

.sessionTaskReportList {
  list-style-type: none;
  margin: 20px 0px 0px 0px;
  padding: 0px;
}

.sessionTaskReportList .taskReportRow {
  margin: 0px 0px 20px 0px;
}

/*		LGR 		*/

/* 	Not Supported */

.browser-container {
  width: 296px;
  height: 128px;
  margin: 70px auto 0px auto;
}

.browser-container .browser {
  width: 128px;
  margin: 0px 10px;
  height: 128px;
  float: left;
}

.browser-container .chrome {
  background-image: url('../../../public/assets/images/icons/browsers/chrome_128x128.png');
}

.browser-container .firefox {
  background-image: url('../../../public/assets/images/icons/browsers/firefox_128x128.png');
}

.browser-container .safari {
  background-image: url('../../../public/assets/images/icons/browsers/safari_128x128.png');
}

/*		Session Matrix Page 	*/

/* 	Session Page */

.task-reports li:nth-child(even),
.taskreports li.taskReportRow:nth-child(odd) {
  background-color: var(--white);
}

.taskreports .TaskParticipation {
  margin: 0px 12px 0px 0px;
  cursor: move;
}

.sessionTaskReport {
  margin: 0px 0px 80px 0px;
}

.sessionTaskReportList {
  list-style-type: none;
  margin: 20px 0px 0px 0px;
  padding: 0px;
}

.sessionTaskReportList .taskReportRow {
  margin: 0px 0px 20px 0px;
}

.pr2-sessionPageHeader {
  margin-top: 80px;
  background-color: var(--behaviorPurple);
  margin-bottom: 14px;
}

.pr2-sessionPageHeaderContainer {
  display: flex;
  color: var(--white);
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}

.pr2-sessionPageHeaderEditBtn {
  width: 28px;
  height: 28px;
  background-size: 28px;
  margin-left: auto;
  border-radius: 50%;
  background-image: url('../../../public/assets/images/library/session-edit-icon.svg');
  background-repeat: no-repeat;
}

.pr2-sessionPageHeader .pr2-sessionPageHeaderSubject {
  font-size: 12px;
  font-family: 'HelveticaNeue-Light';
  margin: 0px 0px 5px 0px;
}

.pr2-sessionPageHeader .pr2-sessionPageHeaderTitle {
  font-size: 25px;
  font-family: 'HelveticaNeue-Bold';
  margin: 0px 0px 5px 0px;
}

.pr2-sessionPageHeader .pr2-sessionPageHeaderParticipants,
.pr2-sessionPageHeader .pr2-sessionPageHeaderDates {
  font-size: 14px;
  font-family: 'HelveticaNeue-Light';
  margin: 0px;
}

.pr2-sessionPageHeader .pr2-sessionPageHeaderParticipants {
  margin-bottom: 4px;
  display: flex;
}

.pr2-sessionPageHeaderParticipantSearch {
  width: 16px;
  height: 16px;
  background-color: var(--white);
  background-image: url('../../../public/assets/images/library/participant-search.svg');
  background-size: 16px;
  background-repeat: no-repeat;
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
}

.pr2-sessionPageHeader .pr2-sessionPageHeaderImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 16px;
}

.pr2-sessionMainCircleLink {
  background-color: var(--behaviorPurple);
  font-family: 'HelveticaNeue-Bold';
  font-size: 16px;
  padding: 12px 35px;
  margin: 0px auto 48px auto;
  width: 225px;
  display: block;
}

/* Loops behavior panels */

.lp--panelHeader-logo {
  padding: 10px 15px 0px 44px;
  color: var(--behaviorGreen);
  background-image: url(../../../public/assets/images/theme-2/loops-icon-hidden.svg);
  background-size: auto 30px;
  background-repeat: no-repeat;
  background-position: 0px 2px;
  min-height: 40px;
}

.lp--behaviorPanel {
  input,
  input[type='checkbox'] {
    outline: none;
  }

  input::-webkit-input-placeholder {
    color: var(--black);
  }

  input::-moz-placeholder {
    color: var(--black);
  }

  input:-ms-input-placeholder {
    color: var(--black);
  }

  input:-moz-placeholder {
    color: var(--black);
  }

  input[type='text'] {
    opacity: 0.6;

    &:focus {
      opacity: 1;
    }
  }
}

.lp--behaviorPanel-title {
  font-size: 28px;
  margin-bottom: 60px;
  border: none;
  outline: none;
  width: 100%;
  color: var(--darkHorizonBackground);
}

input.lp--behaviorPanel-title {
  border-bottom: 1px solid var(--black_5);

  &:focus {
    border-bottom: 1px solid var(--headerGrey);
  }
}

/* End Loops behavior panels */

/*  Loops Quiz Widget */

.lp--quizWidget {
  font-size: 15px;
  /* padding-bottom: 77px; */

  label {
    font-weight: normal;
    cursor: pointer;
    padding-right: 40px;
  }
}

.lp--quizWidget-questions {
  margin-left: 0;
  margin-right: 0;

  > li {
    position: relative;
    list-style-type: decimal;
  }

  .lp--quizWidget-questionTitle {
    font-size: 18px;
    margin-bottom: 25px;
    font-weight: normal;
    display: block;
  }

  .lp--quizWidget-questionImage {
    width: 40px;
    height: 40px;
    background-color: var(--hoverGrey);
    border-radius: 50%;
    border: 0px solid var(--transparent);
    margin-bottom: 15px;
  }

  .lp--quizWidget-question-answers {
    margin: 0px 0px 60px 34px;

    &.lp--quizWidget-question-answers-editor {
      margin-bottom: 0;

      .lp--quizWidget-question-answer {
        border-bottom: 1px solid var(--headerGrey);
      }
    }
  }

  .lp--quizWidget-question-answer {
    line-height: 30px;
    margin: 0px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
    position: relative;
    font-size: 15px;

    .pr2--formInput {
      font-size: 15px;
      padding-right: 30px;
    }
  }

  .lp--quizWidget-question-checkbox {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 3px solid var(--slateGrey);
    border-radius: 50%;
    margin: 0px;
    vertical-align: middle;
    margin: 0px 0px 5px 0px;
    background-size: 10px 10px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .lp--quizWidget-question-checkbox {
    &:checked {
      background-image: url('../../../public/assets/images/theme-2/checkmark.svg');
      background-color: var(--behaviorGreen);
      border-color: var(--behaviorGreen);
    }

    &:hover,
    &:focus {
      background-image: url('../../../public/assets/images/theme-2/checkmark.svg');
      background-color: var(--behaviorGreen);
      border-color: var(--behaviorGreen);
      opacity: 0.6;

      &:checked {
        /* Making the actual check action more satisfying .tom */
        opacity: 0.7;
      }
    }
  }
}

.lp--quizWidget-footer {
  border-top: 1px solid var(--slateGrey);
  text-align: right;
  padding: 21px;
  position: fixed;
  background: var(--white);
  bottom: 0;
  left: 0;
  right: 0;

  .lp--quizWidget-submit {
    color: var(--behaviorGreen);
    border: 1px solid var(--behaviorGreen);
    border-radius: 17px;
    height: 34px;
    padding: 0px 20px;
    background-color: var(--transparent);
  }

  .lp--quizWidget-submit:hover,
  .lp--quizWidget-submit:focus {
    background-color: var(--behaviorGreen);
    color: var(--white);
  }
}

.lp--quizWidgetEditMode {
  .pr2--formInput {
    width: 100%;
    color: var(--darkHorizonBackground);
    border-bottom: 1px solid var(--black_5);
    opacity: 0.6;
  }

  .pr2--formInput:focus {
    border-bottom: 1px solid var(--headerGrey);
    opacity: 1;
  }

  .lp--quizWidget-question-answer {
    border-bottom: 0px solid var(--transparent) !important;
  }

  .pr2--quizWidget-question-delete {
    -webkit-appearance: none;
    display: block;
    position: absolute;
    cursor: pointer;
    margin: 0px;
    width: 10px;
    height: 10px;
    float: right;
    background-image: url('../../../public/assets/images/theme-2/close-black.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: var(--transparent);
    opacity: 0.2;
    top: 5px;
    right: -20px;
    outline: none;
    border: 0px solid var(--transparent);
    padding: 0;
    display: none;
  }

  .pr2--quizWidget-question-delete:hover,
  .pr2--quizWidget-question-delete:focus {
    opacity: 0.8;
  }

  .pr2--formInput:focus ~ .pr2--quizWidget-question-delete,
  .pr2--quizWidget-question-delete:hover,
  .pr2--quizWidget-question-delete:focus {
    display: block;
  }

  .lp--quizWidget-add-question,
  .lp--quizWidget-add-alternative {
    display: block;
    line-height: 30px;
    height: 32px;
    position: relative;
    color: var(--darkHorizonBackground);
    border-bottom: 1px solid var(--black_5);
    opacity: 0.6;
    background: var(--transparent);
    padding: 0px 0px 0px 0px;
  }

  .lp--quizWidget-add-question {
    margin: 0px 0px 42px 0;
    font-size: 18px;
    width: 100%;
  }

  .lp--quizWidget-add-alternative {
    margin: 0px 0px 60px 34px;
    width: calc(100% - 34px);
    font-size: inherit;
    height: 33px;

    &:before {
      content: ' ';
      display: block;
      width: 23px;
      height: 23px;
      background-color: var(--darkHorizonBackground);
      background-image: url('../../../public/assets/images/theme-2/add-icon-white.svg');
      background-size: 9px;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: -34px;
      transform: translateY(-50%);
      border-radius: 50%;
    }
  }
}

.lp--redoableCheck[type='checkbox'] {
  margin-top: 0px;

  &:after {
    content: attr(placeholder);
    display: inline-block;
    width: 150px;
    padding-right: 5px;
    position: relative;
    left: 20px;
  }
}

.lp--redoableLabel {
  font-weight: 300;
  margin-left: 8px;
  cursor: pointer;
  color: var(--black_7);
  transition: color 0.2s ease;
  margin-right: 41px;

  &:hover,
  &:focus {
    color: var(--darkHorizonBackground);
    transition: color 0.2s ease;
  }
}

/* End of Loops Quiz Widget */

.pr2-sessionActivityUserImage {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 50%;
  background-size: 24px;
  display: inline-block;
}

#masqueraded {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--orange);
  z-index: 10000;
  height: 2em;
  line-height: 2em;
  padding: 0 6px;
  color: var(--white);
  opacity: 0.5;
  pointer-events: none;

  a {
    pointer-events: auto;
  }
}

.new-in-loops {
  position: absolute;
  text-align: center;
  font-size: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 200px;
  width: 100%;
  margin-top: -80px;

  @media screen and (min-width: 768px) {
    width: 200px;
    left: calc(50% - 100px);
    margin-top: 60px;
  }

  @media screen and (min-width: 1240px) {
    position: fixed;
    left: 70px;
    top: 10%;
    margin-top: 0;
  }

  &__header {
    font-size: 20px;
    color: var(--white);
  }

  &__read-more {
    font-weight: 400;
    margin-top: 10px;
    color: var(--buttonGreenFront);
  }

  &__button-wrapper {
    margin-top: 10px;
  }
}

.lp--graphZoomButton {
  position: absolute;
  right: 24px;
  bottom: 30px;
  display: flex;
  z-index: 6;
  flex-direction: column;
  transition: right 0.5s;

  @media screen and (min-width: 768px) {
    display: none;
  }

  .lp--graphZoomButtonPlus,
  .lp--graphZoomButtonCenter,
  .lp--graphZoomButtonMinus {
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    outline: none;
    display: block;
    width: 30px;
    height: 40px;
    cursor: pointer;
    padding: 0px;
    background-color: var(--behaviorGreenBack);

    &:hover,
    &:focus {
      background-color: var(--minimumGreenIcon);
    }
  }

  .lp--graphZoomButtonCenter {
    margin-bottom: 1px;
    background-size: 14px;
    background-image: url(../../../public/assets/images/theme-2/icons/map-center-white.svg);
    border-radius: 0px;
  }

  .lp--graphZoomButtonPlus {
    margin-bottom: 1px;
    border-radius: 15px 15px 0px 0px;
    background-size: 10px;
    background-image: url(../../../public/assets/images/theme-2/icons/map-plus-white.svg);
  }

  .lp--graphZoomButtonMinus {
    border-radius: 0px 0px 15px 15px;
    background-size: 10px;
    background-image: url(../../../public/assets/images/theme-2/icons/map-minus-white.svg);
  }
}

.remote-control {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 6;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  transform: rotate(45deg);
  transition: right 0.5s;

  @media screen and (max-width: 767px) {
    display: none;
  }

  @media screen and (min-width: 768px) {
    .guide-open &,
    .trial-open & {
      transition: right 0.5s;
      right: 457px;
    }
  }

  .outer-circle {
    float: left;
    width: 50px;
    height: 50px;
    background-color: var(--behaviorGreenBack);
    border: 0.5px inset var(--black_2);
    box-shadow: inset -2px -2px 15px var(--black_2);
    transition: background-color 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus {
      background-color: var(--minimumGreenIcon);
    }

    .up & {
      border-top: none;
      border-left: none;
    }
  }

  .inner-circle {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: var(--behaviorGreenBack);
    border: 0.5px inset var(--black_2);
    box-shadow: inset -2px -2px 15px var(--black_2);
    transition: background-color 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus {
      background-color: var(--minimumGreenIcon);
    }
  }

  .icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
  }

  .outer-circle {
    &.up {
      border-top: none;
      border-left: none;

      .icon {
        background-image: url(../../../public/assets/images/theme-2/icons/map-plus-white.svg);
      }
    }

    &.right {
      border-top: none;
      border-right: none;

      .icon {
        width: 17px;
        height: 17px;
        transform: rotate(-45deg);
        background-image: url(../../../public/assets/images/theme-2/loops-icon-white.svg);
      }
    }

    &.down {
      border-bottom: none;
      border-right: none;

      .icon {
        transform: rotate(-45deg);
        background-image: url(../../../public/assets/images/theme-2/icons/map-minus-white.svg);
      }
    }

    &.left {
      border-bottom: none;
      border-left: none;

      .icon {
        width: 16px;
        height: 16px;
        transform: rotate(-45deg);
        background-image: url(../../../public/assets/images/theme-2/icons/map-question-white.svg);
      }
    }
  }

  .inner-circle {
    &.center {
      .icon {
        width: 20px;
        height: 20px;
        background-image: url(../../../public/assets/images/theme-2/icons/map-center-white.svg);
      }
    }
  }
}

.lp--graphQuickLink {
  position: absolute;
  right: initial;
  z-index: 6;
  bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: var(--white);
  transition: right 0.5s;

  @media screen and (min-width: 768px) {
    right: 150px;
    .guide-open &,
    .trial-open & {
      transition: right 0.5s;
      right: 580px;
    }
  }
}

.lp--graphQuickLinkItem {
  padding: 0 12px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  height: 36px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 12px center;
  padding-left: 38px;
  border: 2px solid var(--transparent);
  transition: border-color 0.2s ease;
  color: var(--black);
  font-weight: bold;

  &:hover,
  &:focus {
    color: var(--black);
    border-color: var(--white);
    transition: border-color 0.2s ease;
  }

  &.lp--graphQuickLinkWhat {
    background-color: var(--loopsGreen);
    background-image: url(../../../public/assets/images/theme-2/icons/graph-buttons/start.svg);
  }

  &.lp--graphQuickLinkEdit {
    background-color: var(--loopsGreen);
    background-image: url(../../../public/assets/images/theme-2/icons/graph-buttons/edit.svg);
    margin-left: 7px;
  }

  &.lp--graphQuickLinkTeacherPanel {
    background-color: var(--loopsGreen);
    background-image: url(../../../public/assets/images/theme-2/icons/link_arrow.svg);
    margin-right: 7px;
  }

  &.lp--graphQuickLinkPreview {
    background-color: var(--loopsGreen);
    background-image: url(../../../public/assets/images/theme-2/icons/graph-buttons/preview.svg);
  }

  &.lp--graphQuickLinkUse {
    background-color: var(--loopsGreen);
    background-image: url(../../../public/assets/images/theme-2/icons/graph-buttons/start.svg);
    margin-left: 7px;
  }

  &.lp--graphQuickLinkLeave {
    background-color: var(--loopsGreen);
    padding-left: 12px;
  }
}

.lp--graphQuickLinkTurnIn {
  background-color: var(--loopsGreen);
  padding-left: 12px;
  margin-left: 9px;
}

.lp--errorPageJumbotron {
  color: var(--white);
  height: 600px;
  overflow: hidden;
  padding-top: 135px;

  .lp--errorPageJumbotron-container {
    height: 100%;
    display: flex;
    align-items: center;
    align-content: space-around;
    flex-wrap: wrap;
    padding: 0px 0px 0px 88px;

    .lp--continueLink {
      color: var(--behaviorGreen);
      display: inline-block;
      margin: 20px 0px;
      font-weight: bold;
      font-size: 23px;
      border-bottom: 1px solid var(--behaviorGreen);
    }
  }
}

select {
  cursor: pointer;

  .dropdown-option {
    background-color: var(--black_8);
    color: var(--grey);
  }
}

.navbar-brand {
  margin-top: 0;
}

.navbar {
  > .container-fluid {
    display: flex;
    justify-content: space-between;

    &:before,
    &:after {
      content: none;
    }
  }
}

.navbar-header {
  &:before,
  &:after {
    content: none;
  }
}

@media (max-width: 767px) {
  .navbar-default {
    &.navbar-fullscreen.fullscreen {
      background: var(--black);
      overflow: auto;
      height: 100%;
      position: fixed;
    }
  }

  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus {
    background: var(--transparent);
  }

  .navbar-default .navbar-toggle.active {
    background: var(--white);
    border: 1px solid var(--white_1);
  }

  .navbar-toggle {
    position: relative;
    right: 10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    .icon-bar {
      width: 12px;
    }
  }

  .navbar-default .navbar-toggle {
    .icon-bar {
      background-color: var(--white);
    }

    &.active .icon-bar {
      background-color: var(--black);
    }
  }

  .navbar-default .navbar-nav {
    margin-bottom: 0;

    > li {
      height: 50px;
      min-height: 50px;
      background-color: var(--white_1);
      border-top: 1px solid var(--white_1);

      > a {
        color: var(--white);
        font-weight: 400;

        width: 100%;
        height: 100%;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        min-height: 50px;

        &:hover,
        &:focus {
          background: var(--white_1);
        }
      }
    }

    li.lp--navActiveLink > a {
      &:after {
        left: 0;
        top: 16px;
        height: 18px;
        width: 4px;
      }

      &:hover,
      &:focus {
        background: var(--transparent);
        cursor: default;
        color: var(--white) !important;
      }
    }
  }

  .navbar-header {
    display: flex;
    justify-content: space-between;
    float: none;
  }

  .navbar {
    > .container-fluid {
      display: block;
    }
  }
}

@media (max-width: 1024px) {
  .navbar-brand {
    margin-top: 0;
  }
}

.navbar-left {
  display: flex;
}

.menu-progress {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

.body.frontpage {
  overflow: hidden;

  .navbar,
  .hero,
  .about,
  .divider-section,
  .footer {
    display: none;
  }

  .pr2--js-closeBtn {
    display: none;
  }

  #panel-mask {
    background-color: var(--darkHorizonBackground);
  }
}

.lp--authenticateModal {
  .lp--authenticateModalBody {
    h1,
    h2,
    h3 {
      margin-top: 40px;
      margin-bottom: 20px;
    }

    h1 {
      font-weight: 300;
      line-height: 48px;
    }

    h2,
    h3 {
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
    }
  }
}

.lp--authenticateModal.lp--inviteModal h2 {
  margin-bottom: auto;
  margin-top: auto;
}

.lp--teams-signin {
  background-color: var(--white);
  color: var(--menuButtonBorder);
  font-weight: 600;
  font-family: 'Segoe UI', Tahoma, Helvetica, Sans-Serif;
  font-size: 15px;
  height: 39px;
  line-height: 39px;
  border: 1px solid var(--headerGrey);
  padding: 0 12px 0 44px;
  background-position: 11px center;
  background-repeat: no-repeat;
  background-size: 21px 21px;
  background-image: url('../../../public/assets/images/ms-symbollockup_mssymbol_19.svg');
}

.lp--feide-signin {
  background-color: var(--white);
  color: var(--menuButtonBorder);
  font-weight: 600;
  font-family: 'Segoe UI', Tahoma, Helvetica, Sans-Serif;
  font-size: 15px;
  height: 39px;
  line-height: 39px;
  border: 1px solid var(--headerGrey);
  padding: 0 12px 0 44px;
  background-position: 11px center;
  background-repeat: no-repeat;
  background-size: 21px 21px;
  background-image: url('../../../public/assets/images/feide-logo.svg');
}

.teacher_panel--loading {
  position: absolute;
  top: 40%;
  left: 50%;
  color: var(--white);
  font-size: 20px;
  font-weight: bold;
  opacity: 0;
  transition: 0.3s opacity ease-in-out;

  &.visible {
    opacity: 1;
    transition: 0.3s opacity ease-in-out;
  }

  &:before {
    width: 21px;
    height: 21px;
    content: '';
    position: absolute;
    left: 20px;
    top: -30px;
    background-size: 21px 21px;
    background-image: url('../../../public/assets/images/theme-2/loops-icon-white.svg');
    animation: rotating 2s linear infinite;
  }
}

.lp--activityEditor .custom-embed {
  position: relative;

  &.visible {
    display: block;
  }

  .image {
    pointer-events: none;
  }
}

.file,
.oembed,
.embed-popup {
  .embed-template {
    border: 1px solid var(--black_3);
    border-radius: 8px;
    margin: 15px 1px;
    padding: 10px;
    display: flex;
    background-color: var(--white);
    color: var(--black) !important;
    text-decoration: none !important;
    align-items: center;
    transition: box-shadow 0.2s ease;
    cursor: pointer;

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px var(--black_5);
      transition: box-shadow 0.2s ease;
    }

    &.selected {
      border-color: var(--black_8);
      border-width: 2px;
    }

    &.template_0 {
      min-height: 44px;
      justify-content: center;
      background-image: url('../../../public/assets/images/theme-2/icons/text-editor/link-out.svg');
      background-repeat: no-repeat;
      background-size: 17px 17px;
      background-position: 10px;
      padding: 10px 40px;
    }
  }

  .image-preview {
    margin-right: 20px !important;
    flex-shrink: 0;
    display: flex;
  }

  .image {
    object-fit: cover;
    cursor: pointer !important;
  }

  .template_1,
  .template_4,
  .template_6 {
    .image,
    .no-image {
      border-radius: 8px;
    }
  }

  .template_2,
  .template_3,
  .template_5 {
    .image,
    .no-image {
      border-radius: 50%;
    }
  }

  .template_1 .image {
    width: 160px;
    height: 105px;
  }

  .template_2 .image,
  .template_5 .image {
    width: 55px;
    height: 55px;
  }

  .template_3 .image {
    width: 95px;
    height: 95px;
  }

  .template_4 .image,
  .template_6 .image {
    width: 80px;
    height: 53px;
  }

  .no-image {
    border: 1px solid var(--black_3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .template_1 .no-image {
    min-width: 160px;
    height: 105px;
  }

  .template_2 .no-image,
  .template_5 .no-image {
    min-width: 55px;
    height: 55px;
  }

  .template_3 .no-image {
    min-width: 95px;
    height: 95px;
  }

  .template_4 .no-image,
  .template_6 .no-image {
    min-width: 80px;
    height: 53px;
  }

  .title-preview {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 20px !important;
    font-size: 21px;
    font-weight: bold;
  }

  .template_0 .title-preview {
    font-size: 14px;
    font-weight: normal;
  }

  .template_1,
  .template_3 {
    .title-preview {
      margin-top: 5px;
    }
  }

  .template_6 {
    .title-preview {
      align-items: center;
    }
  }

  .template_2,
  .template_4,
  .template_5 {
    .title-preview.has-description {
      width: 35%;
    }
  }

  .description-preview {
    font-size: 14px;
    font-weight: normal;
    margin-right: 20px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .template_1,
  .template_3 {
    .description-preview {
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }

  .template_2,
  .template_4,
  .template_5 {
    .description-preview {
      white-space: nowrap;
      display: block;
      margin-top: 5px;
      &.has-title {
        width: 55%;
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
  }

  .template_4 .wrapper {
    width: calc(100% - 120px);
  }

  .template_2 .wrapper,
  .template_5 .wrapper {
    width: calc(100% - 95px);
  }

  .column-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
  }

  .template_1 .column-wrapper {
    width: calc(100% - 180px);
  }

  .template_3 .column-wrapper {
    width: calc(100% - 115px);
  }
}
