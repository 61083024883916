.prSettingsType {
  padding-top: 30px;
  clear: both;
}

.lp--settingsPanel-sectionHeader {
  color: var(--white_6);
  font-size: 13px;
  padding-bottom: 10px;
  margin-bottom: 11px;
  border-bottom: 1px solid var(--white_1);
  display: flex;

  > a {
    margin-left: auto;
  }
}

.lp--settingsPanel-contextList-headers {
  display: flex;
  justify-content: space-between;
  color: var(--white_3);
  font-size: 12px;
  margin-bottom: 15px;
}

.lp--settingsPanel-contextList-header {
  width: 27.5%;
  text-align: center;
  &:first-child {
    width: 45%;
    text-align: left;
  }
}

.lp--settingsPanel-contextListItem {
  display: flex;
  height: 40px;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus,
  &.open {
    background: var(--black_1);
    align-items: center;
    margin-left: -40px;
    padding-left: 40px;
  }
}

.lp--settingsPanel-contextList-column {
  width: 27.5%;
  text-align: center;
  color: var(--grey);
  font-weight: 400;
  &:first-child {
    width: 100%;
    text-align: left;
  }
}

.lp--settingsPanel-removeParticipant {
  padding-left: 17px;
  margin-left: 25px;
  background: url('../../../public/assets/images/theme-2/icons/white-trash.svg') center left no-repeat;
  background-size: 14px;
  background-position-y: 1px;
  color: var(--white);
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;

  &:hover,
  &:focus {
    transition: opacity 0.2s ease-in-out;
    color: var(--white);
    opacity: 0.8;
  }
}

.lp--settingsPanel-section {
  display: none;

  &.lp--settingsPanel-activeSection {
    display: block;
  }
}

.prSettings-change {
  color: var(--white);
  padding-left: 19px;
  background: url('../../../public/assets/images/theme-2/icons/add-setting-white.svg') center left no-repeat;
  background-size: 14px 14px;
  display: inline-block;
  opacity: 0.6;
  transition: 0.2s;
  &:hover,
  &:focus {
    opacity: 1;
  }
}
