.lp--codeInput {
  input {
    display: inline-block;
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 24px;
    border-radius: 0;
    padding: 0;
    margin-right: 8px;
    text-align: center;
    font-weight: normal;
    color: var(--standardBlackBackground);
    font-family: Roboto, sans-serif;
    background: var(--white);
    border-radius: 50%;

    &::placeholder {
      color: var(--hoverGrey);
      opacity: 1;
    }

    &:last-child {
      margin-right: 0px;
    }
  }

  &.lp--codeInputValid {
    input {
      background-color: var(--behaviorGreen);
      color: var(--white);
    }
  }
}

@media (max-width: 808px) {
  .lp--codeInput {
    input {
      margin-right: 2px;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--white);
  transition: background-color 5000s ease-in-out 0s;
}
