.lp--authenticateModal {
  font-family: 'Roboto', sans-serif;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: unset;

  .new-in-loops__button-wrapper button {
    color: var(--behaviorGreen);
  }

  footer {
    margin-top: 50px;
  }

  .lp--authenticateModalBody {
    padding: 30px 30px 100px 30px;
    font-weight: 300;
    font-size: 16px;

    h1 {
      font-size: 30px;
      font-weight: 300;
      line-height: 48px;
    }

    h2,
    h3 {
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
    }

    p {
      a {
        color: var(--loopsGreen);
        text-decoration: none !important;
        border-bottom: 1px solid var(--transparent);
        font-weight: 400;
        width: fit-content;
        transition: border-bottom 0.2s ease-in-out;

        &:hover,
        &:focus {
          transition: border-bottom 0.2s ease-in-out;
          border-bottom: 1px solid var(--loopsGreen);
        }
      }

      &.subtitle {
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .lp--radioFormGroup {
      margin: 16px 0px 0px 0px;
      label {
        font-weight: normal;
        color: var(--slateGrey);
      }

      &.country label {
        margin-bottom: 10px;
      }
    }

    .lp--formRadioButtonLabel {
      color: var(--slateGrey);
      padding: 0;
      margin: 0 16px 0 0;

      @media screen and (max-width: 767px) {
        margin-top: 10px;
      }

      input[type='radio'] {
        display: none;
      }

      input[type='radio'] ~ label {
        cursor: pointer;
        background: url(../../../public/assets/images/1.1/modal-signup-radiobutton-false.svg) no-repeat;
        background-position: left center;
        background-size: 20px 20px;
        padding: 0 0 0 28px;
        border: 0;
        margin: 0;
      }

      input[type='radio']:checked ~ label {
        background-image: url(../../../public/assets/images/1.1/modal-signup-radiobutton-true.svg);
      }
    }

    .lp--mobileMarketing {
      color: var(--headerGrey);
      font-style: italic;
      font-size: 14px;
      margin: 12px 0 0 0;
    }

    .lp--formCheckboxLabel {
      color: var(--slateGrey);
      padding: 0;

      input[type='checkbox'] {
        display: none;
      }

      input[type='checkbox'] ~ label {
        cursor: pointer;
        background: url(../../../public/assets/images/icons/checkbox-unselected.svg) no-repeat;
        background-position: left center;
        background-size: 20px 20px;
        padding: 0 0 0 28px;
        border: 0;
        margin: 0;
      }

      input[type='checkbox']:checked ~ label {
        background-image: url(../../../public/assets/images/icons/checkbox-selected.svg);
      }
    }

    .lp--external-identity {
      margin-bottom: 8px;
    }

    .lp--login-row-actions {
      display: flex;
      justify-content: space-between;
    }

    .lp--alternative-action {
      color: var(--slateGrey);

      .lp--authenticateModalTypeSwitch {
        margin-left: 0;
      }
    }

    .lp--formField {
      position: relative;
    }

    .lp--formField .lp--textInput {
      width: 100%;
      background: unset;
      border: 0;
      border-radius: 0;
      padding: 8px 4px;
      font-weight: 300;
      border-bottom: 2px solid var(--headerGrey);
      font-size: 18px;
      line-height: 18px;

      &::placeholder {
        color: var(--white_6);
        font-style: italic;
      }
    }

    .lp--formField.municipality {
      margin: 34px 0 30px 0;
    }

    .pr2--formInput {
      color: var(--white);

      &::placeholder {
        color: var(--white_6);
        font-style: italic;
      }
    }

    .lp--formSubmit {
      background: var(--loopsGreen);
      cursor: pointer;
      border: 2px solid var(--black_5);
      border-radius: 100px;
      height: 36px;
      font-size: 16px;
      color: var(--black);
      padding: 0 18px;
      margin: 0;
      display: inline-block;
      opacity: 1;
      -webkit-appearance: none;
      font-weight: bold;
      transition: border-color 0.2s ease;

      &:disabled {
        opacity: 0.6;
        cursor: default;
      }

      &:not(:disabled) {
        &:hover,
        &:focus {
          border-color: var(--white);
          transition: border-color 0.2s ease;
        }
      }
    }

    .lp--invite {
      background: var(--black_5);
      border: 2px solid var(--white_5);
      border-radius: 100px;
      height: 80px;
      padding: 0 20px;
      transition: border-color 0.2s ease;

      &:hover,
      &:focus {
        border-color: var(--hoverGrey);
        transition: border-color 0.2s ease;
      }

      @media screen and (max-width: 767px) {
        height: auto;
      }

      label {
        display: flex;
        align-items: center;
        height: 80px;
        width: 100%;
        div {
          display: inline-block;
          font-size: 21px;
          color: var(--white);
          span {
            font-size: 16px;
            font-weight: 300;
            color: var(--slateGrey);
          }
        }

        @media screen and (max-width: 767px) {
          height: auto;
        }
      }

      img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        margin: 0 18px 0 8px;
        flex-shrink: 0;

        @media screen and (max-width: 767px) {
          width: 30px;
          height: 30px;
        }
      }
    }

    .login-button {
      padding-right: 30px;
      background-image: url(../../../public/assets/images/theme-2/icons/submit-arrow-black.svg);
      background-repeat: no-repeat;
      background-size: 15px;
      background-position-y: center;
      background-position-x: calc(100% - 10px);
    }

    .lp--userAvatar {
      display: none;
    }

    @media (min-width: 768px) {
      padding: 40px 60px 60px 60px;

      h1,
      h2 {
        font-size: 40px;
      }

      .lp--userAvatar {
        display: inline-block;
      }

      .lp--invite label div {
        font-size: 21px;

        span {
          font-size: 16px;
        }
      }
    }
  }

  background-image: url(../../../public/assets/images/starry-sky.png);

  .lp--authenticateModalBodyViewport {
    position: relative;
  }

  .lp--authenticateModalBodyViewport:after {
    width: 60px;
    height: 100px;
    position: absolute;
    top: 40px;
    right: 60px;
    content: '';
    background-image: url(../../../public/assets/images/loops-astronaut.png);
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: top right;

    @media screen and (max-width: 767px) {
      background-size: 40px;
      top: 60px;
      right: 40px;
    }
  }

  &.lp--inviteModal .lp--authenticateModalBodyViewport:after {
    right: 100px;
  }

  &.lp--authenticateModal-join-code .lp--authenticateModalBodyViewport:after,
  &.lp--authenticateModal-teams-select-path .lp--authenticateModalBodyViewport:after {
    background-image: url(../../../public/assets/images/loops-nodes.png);
    background-size: 150px;
    width: 150px;
    height: 150px;
    top: 80px;
    right: 80px;
  }

  &.lp--authenticateModal-teams-select-path .lp--authenticateModalBodyViewport:after {
    top: 40px;
    right: 40px;
  }

  &.lp--authenticateModal-teams-select-path {
    .lp--authenticateModalBody {
      h3 {
        font-weight: bold;
      }
      .lp--formRow {
        align-items: start;

        div {
          display: flex;
          flex-direction: column;
          align-self: normal;

          p {
            flex: 1;
            margin-top: 0;
          }

          &:first-child {
            margin-right: 32px;

            @media screen and (max-width: 767px) {
              margin-right: 0;
            }
          }

          button {
            align-self: end;
            border: 1px solid var(--loopsGreen);
            color: var(--loopsGreen);
            background: none;
          }
        }
      }
    }
  }

  &.lp--authenticateModal-join-code.no-user .lp--authenticateModalBodyViewport:after {
    top: 40px;

    @media screen and (max-width: 767px) {
      top: 60px;
    }
  }

  &.lp--authenticateModal-join-code.school .lp--authenticateModalBodyViewport:after {
    background-image: url(../../../public/assets/images/astro-loops.png);
  }

  &.lp--authenticateModal-join-code .lp--authenticateModalBodyViewport:after {
    @media screen and (max-width: 767px) {
      background-size: 80px;
      top: 40px;
      right: 40px;
    }
  }

  &.lp--authenticateModal-share-code .lp--authenticateModalBodyViewport #sessionShareCodeCanvas {
    position: absolute;
    width: 164px;
    height: 164px;
    top: 250px;
    right: 80px;

    @media screen and (max-width: 767px) {
      top: 250px;
      right: 30px;
    }
  }

  &.lp--authenticateModal-signup .lp--authenticateModalBodyViewport:after,
  &.lp--authenticateModal-signup_code .lp--authenticateModalBodyViewport:after,
  &.lp--authenticateModal-signup_create.invitation .lp--authenticateModalBodyViewport:after {
    background-image: url(../../../public/assets/images/loops-nodes.png);
    background-size: 100px;
    width: 100px;
    height: 100px;

    @media screen and (max-width: 767px) {
      background-size: 60px;
      top: 20px;
      right: 20px;
    }
  }

  &.lp--authenticateModal-signup_code .lp--authenticateModalBodyViewport:after {
    top: 20px;
    right: 20px;
  }

  &.lp--authenticateModal-signup_create:not(.invitation) .lp--authenticateModalBodyViewport:after {
    background: none;
  }

  &.lp--authenticateModal-signup_create:not(.invitation) .subtitle.organization {
    position: relative;

    &:after {
      background-image: url(../../../public/assets/images/loops-nodes.png);
      background-size: 100px;
      background-repeat: no-repeat;
      background-position: top right;
      width: 100px;
      height: 100px;
      position: absolute;
      top: -30px;
      right: 0px;
      content: '';

      @media screen and (max-width: 767px) {
        background-size: 70px;
        top: -10px;
        right: -10px;
      }
    }
  }

  &.lp--authenticateModal-signup_join_invites {
    .lp--alternative-action {
      text-align: right;

      .lp--js-createLink {
        &.disabled {
          opacity: 0.9;
        }
      }

      .parsley-style-error {
        display: none;
      }
    }
  }

  &.lp--inviteModal .lp--authenticateModalBodyViewport:after,
  &.lp--authenticateModal-signup_join_invites .lp--authenticateModalBodyViewport:after {
    background-image: url(../../../public/assets/images/loops-nodes.png);
    background-size: 130px;
    width: 130px;
    height: 130px;

    @media screen and (max-width: 767px) {
      background-size: 80px;
      top: 45px;
      right: 40px;
    }
  }

  &.lp--authenticateModal-signup_join_invites .lp--authenticateModalBodyViewport:after {
    background-size: 100px;
    width: 100px;
    height: 100px;

    @media screen and (max-width: 767px) {
      background-size: 80px;
      top: 75px;
      right: 20px;
    }
  }

  &.lp--inviteModal.school .lp--authenticateModalBodyViewport:after,
  &.lp--authenticateModal-signup_join_invites.school .lp--authenticateModalBodyViewport:after {
    background-image: url(../../../public/assets/images/astro-loops.png);
  }
}

.lp--authenticateModal-join-code .code-exists {
  display: none;
}

.lp--inviteModal .lp--inviteModalActions {
  width: 100%;
  margin: 30px 0;

  button {
    min-width: 140px;
  }

  @media (min-width: 768px) {
    width: 60%;
    margin: 30px 0;
  }
}

@media (min-width: 1024px) {
  .lp--modal.lp--signupSection {
    left: 40px;
    margin-left: initial;
  }
}

.show-password-toggle {
  background-image: url(../../../public/assets/images/theme-2/icons/show.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px;
  background-size: 80%;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 4px;
  display: inline-block !important;
  opacity: 0.8;
  transition: opacity 0.2s ease;

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0.2s ease;
  }

  &.show {
    background-image: url(../../../public/assets/images/theme-2/icons/hide.svg);
  }
}

.join-modal__email-header,
.join-modal__login-header {
  font-weight: bold;
}

.join-modal__login-info {
  opacity: 0.7;
}

.join-modal__login-loop {
  font-size: 20px;
}

.join-modal__email-body,
.join-modal__login-body {
  font-family: 'Roboto Light', sans-serif;
}

.lp--authenticateModal .lp--authenticateModalBody p.join-modal__login {
  margin: 35px 0 10px;
}
