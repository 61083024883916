.lp--lightBoxImage {
  top: 0px;
  left: 0px;
  display: block;
  position: fixed;
  width: 100vw;
  height: 100vh;
  cursor: zoom-in;

  &:before {
    opacity: 0;
    content: ' ';
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: var(--white);
    transition: opacity 0.3s;
  }

  .lp--lightBoxImageContainer {
    display: block;
    margin: 0px auto;
    position: absolute;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transition: all 0.3s;
    height: 80vh;
    width: 80vw;
  }

  .lp--lightBoxImageClose {
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
    opacity: 0.4;
    background-image: url(../../../../public/assets/images/tmp/v2/node-panel-close.svg);
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }
}

.lp--lightBoxImageOpen {
  cursor: zoom-out;
  &:before {
    opacity: 1;
  }
}

.lp--js-imageDisplayedInLightbox {
  opacity: 0;
}
