@import '~quill/dist/quill.core.css';

.lp--tab-container {
  display: none;

  &.active {
    display: block;
  }
}

.lp--add-to-collection-wrapper {
  position: absolute;
  text-align: center;

  .lp--add-to-collection {
    width: 400px;
    border: 1px solid var(--hoverGrey);
    box-shadow: 0 2px 5px var(--black_3);
    border-radius: 6px;
    padding: 12px;
    position: absolute;
    background: var(--white);
    z-index: 2;
    right: -209px;
    bottom: 10px;

    &:after,
    &:before {
      top: 100%;
      left: 50%;
      border: solid var(--transparent);
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: var(--transparent);
      border-top-color: var(--white);
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
      border-color: var(--transparent);
      border-top-color: var(--hoverGrey);
      border-width: 11px;
      margin-left: -11px;
    }

    .lp--closeBtn {
      position: absolute;
      left: auto;
      right: 5px;
      top: 5px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      background-image: url(../../../public/assets/images/theme-2/close-black.svg);
      background-size: 12px 12px;
      opacity: 0.6;
      background-repeat: no-repeat;
      background-position: center center;
      display: block;
      transition: opacity 0.2s ease;

      &:hover,
      &:focus {
        opacity: 1;
        transition: opacity 0.2s ease;
      }
    }

    h1 {
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      color: var(--menuButtonBorder);
      padding: 0 0 4px 0;
    }

    .save {
      margin: 16px 0 0 0;
      display: inline-block;
      padding: 6px 20px;
      border-radius: 18px;
      background-color: var(--buttonGreenFront);
      text-align: center;
      color: var(--black);
      position: relative;
      border: 2px solid var(--transparent);
      transition: border-color 0.2s ease;
      font-weight: bold;

      &:hover,
      &:focus {
        border-color: var(--black_5);
        transition: border-color 0.2s ease;
      }
    }

    .lists {
      clear: both;
      padding-top: 16px;
    }

    ul {
      overflow: auto;
      max-height: 240px;
      text-align: left;
      display: none;
      overflow-x: hidden;

      &.active {
        display: block;
      }

      li {
        color: var(--black_8);
        font-size: 14px;
        font-weight: bold;
        display: block;
        min-height: 28px;
        line-height: 28px;
        padding: 0 0 0 32px;
        margin: 0 0 4px 0;
        cursor: pointer;
        position: relative;
        transition: background-color 0.2s ease;

        &[data-toggable='false'] {
          cursor: default;
        }

        &:hover,
        &:focus {
          background-color: var(--black_1);
          transition: background-color 0.2s ease;
        }

        &:before {
          background: url('../../../public/assets/images/collection-filled-circle.png') no-repeat left center;
          background-size: 20px 20px;
          content: ' ';
          position: absolute;
          width: 20px;
          height: 20px;
          top: 5px;
          left: 5px;
        }

        &.selected:before {
          background-image: url('../../../public/assets/images/theme-2/icons/checkmark.svg');
          background-size: 10px 10px;
          background-position: center center;
          background-color: var(--buttonGreenBack);
          border-radius: 400px;
        }

        &.new {
          cursor: default;

          &:hover,
          &:focus {
            background-color: var(--transparent);
          }

          input {
            border-width: 0 0 1px 0;
            border-style: solid;
            border-color: var(--hoverGrey);
            width: 306px;
            padding-bottom: 3px;
            outline: none;
          }
        }
      }
    }
  }
}

.lp--loopListItem-add {
  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.lp--loopListItem-private {
  .lp--loopListItem-image,
  .lp--loopListItem-title {
    opacity: 0.5;
  }

  .lp--privateItemMarker {
    background-image: url(../../../public/assets/images/theme-2/icons/toolbar/hidden.svg);
    background-size: 18px 18px;
    width: 18px;
    position: absolute;
    right: 32px;
    height: 18px;
  }
}

/*	Image Uploader - duplicate of style in panels.css.scss, move to shared location? */
.pr2--imageUploader {
  width: auto;
  padding: 0px 8px;
  height: 35px;
  border-radius: 18px;
  border: 2px solid var(--white_7);
  transition: border-color 0.2s ease;

  &:hover,
  &:focus {
    border-color: var(--white_9);
    transition: border-color 0.2s ease;
  }

  ::-webkit-file-upload-button,
  input[type='file'] {
    cursor: pointer;
  }
}

body.edit {
  border: 2px solid var(--behaviorGreen);
}
